<template>
  <el-card class='account-remittance-not-account business-handling my-card' :body-style="cardBody">
    <el-page-header @back="$router.go(-1)" :title="$t('Back')">
      <template slot="content"><span style="font-size:14px">{{$t('drawMoneyBusiness')}} / {{$t('accountDrawMoney')}}</span></template>
    </el-page-header>
    <div class="form-container">
      <el-divider content-position="left">
        <el-form inline ref="queryForm" :model="queryForm" :rules="rules" style="height:45px;overflow:hidden;">
          <el-form-item :label="$t('withdrawerInfo')" prop="param" style="margin-bottom:0">
            <el-input size="mini" v-model="queryForm.param" :placeholder="$t('inputPhoneOrAccount')"></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom:0">
            <el-button size="mini" type="primary" @click="onQuery('queryForm')">{{$t('query')}}{{$t('withdrawerInfo')}}</el-button>
          </el-form-item>
        </el-form>
      </el-divider>
      <!-- 存款信息 -->
      <el-form v-if="remitterInfo" ref="remittanceForm" :model="remittanceForm" :rules="rules" label-position="top">
        <el-input type="hidden" size="medium" v-model="remitterInfo.businessCode" disabled></el-input>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item :label="$t('firstName')">
              <el-input size="medium" v-model="remitterInfo.firstName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('lastName')">
              <el-input size="medium" v-model="remitterInfo.lastName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('phone')">
              <el-input size="medium" v-model="remitterInfo.phone" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('idNumber')">
              <el-input size="medium" v-model="remitterInfo.idCard" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('accountAmount')">
              <el-input size="medium" v-model="remitterInfo.accountAmount" disabled>
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('account')">
              <el-input size="medium" v-model="remitterInfo.cardNo" disabled></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item :label="$t('withdrawerAddress')">
              <el-cascader size="medium" v-model="remittanceForm.country_region_city" :options="countryTree" :props="cascader" disabled placeholder=" "></el-cascader>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-divider content-position="left">{{$t('withdrawalInfo')}}</el-divider>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item :label="$t('withdrawalAmount')" prop="amount">
              <el-input type="number" size="medium" v-model="remittanceForm.amount" @blur="calculateServiceCharge" :disabled="isCompute">
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item :label="$t('serviceCharge')+' '+(isCompute?$t('calculating')+'……':'')" prop="serviceChargeAmount">
              <el-input size="medium" v-model="remittanceForm.serviceChargeAmount" disabled :placeholder="$t('AfterEnteringTheAmountItWillBAutomaticallyCalculated')">
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <transaction-tax-display businessCode="50" :transaction-amount="remittanceForm.amount"></transaction-tax-display>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('messageAuthenticationCode')" prop="smsCode">
              <el-input size="medium" v-model="remittanceForm.smsCode">
                <el-button slot="append" @click="getSmsVerification" :disabled="surplusTime>0">
                  <span v-if="!surplusTime">{{$t('Send SMS verification code')}}</span>
                  <span v-else>{{$t("send it again")+`(${surplusTime})`}}</span>
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item :label="$t('remark')">
              <el-input size="medium" type="textarea" v-model="remittanceForm.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="bottom-button" v-if="remitterInfo">
      <el-button v-if="!printParam" :loading="isLoading" size="medium" type="primary" @click="onSubmit('remittanceForm')">{{$t('submit')}}</el-button>
      <el-button v-else size="medium" @click="printVisible=true">{{$t('printrRceipt')}}</el-button>
    </div>

    <el-dialog :title="$t('printrRceipt')" :visible.sync="printVisible" width="500px" :modal-append-to-body="false" :destroy-on-close="true" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
      <print-credentials :param="printParam" :isClosePrint.sync="printVisible" />
    </el-dialog>
  </el-card>
</template>

<script>
import printCredentials from '@/components/printCredentials'

import customer from "@/api/customer"
import transactionInfo from "@/api/transactionInfo";
import TransactionTaxDisplay from '@/components/TransactionTaxDisplay'
import business from '@/api/business'

export default {
  components: { printCredentials, TransactionTaxDisplay },
  data () {
    return {
      /**查询存款人信息 */
      queryForm: {},
      /**存款人信息 */
      remitterInfo: null,
      /**存款信息 */
      remittanceForm: {},
      /**取款人信息 */
      payeeForm: {},

      commissionRules: [],

      surplusTime: 0,

      isLoading: false,
      /**手续费计算中 */
      isCompute: false,

      printVisible: false,
      printParam: null,
    }
  },
  computed: {
    cascader () { return this.$store.state.format.cascader },
    cardBody () { return this.$store.state.style.cardBody },
    countryTree () { return this.$store.state.globalParameter.countryTree; },
    rules () {
      return {
        param: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        amount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        serviceChargeAmount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        smsCode: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    }
  },
  methods: {
    calculateServiceCharge () {
      let _this = this;

      if (!_this.remittanceForm.amount) {
        _this.$set(_this.remittanceForm, "serviceChargeAmount", undefined)
        return;
      }

      _this.isCompute = true;
      business.serviceChargeCompute({
        param: { amount: _this.remittanceForm.amount, businessCode: _this.$route.params.code },
        success: res => {

          setTimeout(() => {
            if (res.code != 200) _this.$message.warning(_this.$t(res.msg));
            _this.$set(_this.remittanceForm, "serviceChargeAmount", res.data || 0);

            _this.isCompute = false;
          }, 500);
        }
      })
    },
    onQuery (refName) {
      let _this = this;
      this.$refs[refName].validate(valid => {
        if (valid) {
          let p = {};
          if (_this[refName].param.length === 15) p.cardNo = _this[refName].param;
          else p.phone = _this[refName].param;

          customer.getByAccountOrPhone({
            param: p,
            success: res => {
              if (res.code != 200) {
                _this.$message.warning(_this.$t(res.msg))
                return;
              }
              _this.remitterInfo = {
                businessCode: _this.$route.params.code,
                firstName: res.data.name,
                lastName: res.data.surname,
                phone: res.data.phoneNo,
                idCard: res.data.idCard,
                accountAmount: res.data.accountAmount,
                cardNo: res.data.cardNo,
                cardId: res.data.cardId
              };
            }
          })
        }
        else return false;
      })
    },
    getSmsVerification () {
      let _this = this;
      transactionInfo.sendVerificationCode({
        param: { phone: this.remitterInfo.phone }, success: function (res) {
          if (res.code != 200) {
            _this.$message.warning(_this.$t(res.msg))
            return
          }

          _this.surplusTime = 60;
          let interval = setInterval(() => {
            _this.surplusTime -= 1;
            if (_this.surplusTime === 0)
              clearInterval(interval);
          }, 1000)

          _this.$message.success(_this.$t("SMS verification code sent successfully") + "，" + _this.$t("Verification code Receiving number") + _this.remitterInfo.phone);
        }
      })
    },
    onSubmit (refName) {
      let _this = this;
      _this.$refs[refName].validate(valid => {
        if (!valid) return false;
        _this.isLoading = true;
        let p = Object.assign(_this[refName], _this.remitterInfo);
        transactionInfo.customerWithdrawals({
          param: p,
          success: res => {
            if (res.code == 200) {
              _this.$message.success(_this.$t('operateSuccessfully'));
              _this.$set(_this, "printParam", res.data)
              _this.$set(_this, "printVisible", true)
            }
            else {
              _this.$message.warning(_this.$t(res.msg));
            }
            _this.isLoading = false;
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.account-remittance-not-account.business-handling {
  height: 100%;
  width: 100%;
  .el-card__body {
    position: relative;
    .form-container {
      position: absolute;
      top: 60px;
      bottom: 80px;
      left: 20px;
      right: 0;
      padding-right: 20px;
      overflow: auto;
    }
  }
  .el-form {
    height: auto;
  }
}
</style>